<template>
   <div class="container-fluid">
      <div class="row">
         <div class="col-12">
            <div class="page-title-box">
               <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                     <!-- <li class="breadcrumb-item active">Locations</li> -->
                  </ol>
               </div>
               <h4 class="page-title">Permissions</h4>
            </div>
         </div>
      </div>
      <!-- end page title -->

      <div class="row">
         <div class="col-lg-12">
            <div class="card">
               <div class="card-body">
                  <div class="d-flex justify-content-end">
                     <router-link :to="{ name: 'permission-create' }">
                        <button type="button" class="
                                    btn btn-sm btn-blue
                                    waves-effect waves-light
                                    mb-2
                                 ">
                           <i class="mdi mdi-plus-circle"></i> Add Permission
                        </button>
                     </router-link>
                  </div>

                  <place-holder v-if="loading"></place-holder>

                  <div class="table-responsive">
                     <table class="table table-striped dt-responsive w-100 mb-3" id="permisssion-datatable"
                        v-show="!loading">
                        <thead>
                           <tr>
                              <th>No.</th>
                              <th>Name</th>
                              <th>Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr v-for="(permission, index) in permissions" :key="index">
                              <td>{{ ++index }}</td>
                              <td>{{ permission.name }}</td>
                              <td>
                                 <router-link class="action-icon" :to="{
                                    name: 'permission-update',
                                    params: { id: permission.id },
                                 }">
                                    <i class="mdi mdi-square-edit-outline"></i>
                                 </router-link>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <!-- end card -->
         </div>
      </div>
   </div>
</template>

<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";

export default {
   data() {
      return {
         permissions: [],
         loading: false,
         baseUrl: process.env.VUE_APP_BASE_URL,
      };
   },
   methods: {
      async getAllPermissions() {
         this.loading = true;
         this.$Progress.start();
         await axios
            .get(`${this.baseUrl}admin/v1/permissions`)
            .then((response) => {
               this.permissions = response.data.data;
               this.loading = false;
               this.$Progress.finish();
            });
         $("#permisssion-datatable").DataTable();
      },
      clearFilter() {
         $.fn.dataTable.ext.search.pop();
         $("#permisssion-datatable").DataTable().draw();
      },
   },
   created() {
      this.clearFilter();
      this.getAllPermissions();
   },
};
</script>

<style>
.page-item.active .page-link {
   z-index: 3;
   color: #fff;
   background-color: #4a81d4;
   border-color: #4a81d4;
}
</style>